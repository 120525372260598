import styled from 'styled-components';

import Card from '../../Card';

const ThreadPreviewCard = styled(Card)`
  background-color: ${({ theme }) => theme.color.background500};
  border: 1px solid ${({ theme }) => theme.color.border};
  filter: drop-shadow(0 0 4px ${({ theme }) => theme.color.background900});
  font-weight: normal;
  max-width: ${({ theme }) => theme.dimension.threadPreviewCard.maxWidth};

  &:hover {
    border-color: ${({ theme }) => theme.color.secondaryBorder};
  }
`;

export default ThreadPreviewCard;
