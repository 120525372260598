import CourbetPalette from '../color-palettes/CourbetPalette';

import { IColorTheme } from './IColorTheme';

const PRIMARY_EMPHASIS_COLOR = '#C09FFF';
const SECONDARY_EMPHASIS_COLOR = '#8661CD';

const CourbetDarkColorTheme: IColorTheme = {
  // Text
  color: '#F5F5F9',
  secondaryColor: '#747485',
  tertiaryColor: '#A8A8B9',
  emphasisColor: PRIMARY_EMPHASIS_COLOR,
  secondaryEmphasisColor: SECONDARY_EMPHASIS_COLOR,
  textSelectionColor: '#F5F5F9',

  // Backgrounds
  background100: '#5E5E79',
  background200: '#3E3E5C',
  background300: '#363650',
  background400: '#2F2F48',
  background500: '#24243B',
  background600: '#212137',
  background800: '#1D1D30',
  background900: '#191929',
  background1000: '#141420',
  textSelectionBackground: '#75649C',

  // Borders
  border: '#28283D',
  secondaryBorder: '#363653',
  tertiaryBorder: '#636383',

  // Brand variants
  lightMint: '#9AF2B2',
  lightViolet: '#8885FF',
  lightPurple: '#C09FFF',
  lightTeal: '#72D9D9',

  // Avatar colors
  avatarColors: [
    '#72D9D9',
    '#3B9DE4',
    '#5D75F2',
    '#8885FF',
    '#9AF2B2',
    '#62E285',
    '#92E262',
    '#C4F980',
    '#F9D780',
    '#FBD062',
    '#FBB64E',
    '#DE6E4A',
    '#FC9D75',
    '#FBA2CD',
    '#EE67A7',
    '#D147AA',
  ],

  // Variants
  variant: {
    primary: {
      background: CourbetPalette.purple100,
      border: SECONDARY_EMPHASIS_COLOR,
      color: SECONDARY_EMPHASIS_COLOR,
      text: PRIMARY_EMPHASIS_COLOR,
    },

    secondary: {
      background: CourbetPalette.gray10,
      border: CourbetPalette.gray700,
      color: CourbetPalette.gray700,
      text: CourbetPalette.gray700,
    },

    info: {
      background: CourbetPalette.purple100,
      border: CourbetPalette.purple600,
      color: CourbetPalette.purple700,
      text: CourbetPalette.purple600,
    },

    success: {
      background: CourbetPalette.mint200,
      border: CourbetPalette.mint700,
      color: CourbetPalette.mint800,
      text: CourbetPalette.mint700,
    },

    warning: {
      background: CourbetPalette.orange100,
      border: CourbetPalette.orange700,
      color: CourbetPalette.orange700,
      text: CourbetPalette.orange700,
    },

    danger: {
      background: CourbetPalette.red100,
      border: CourbetPalette.red700,
      color: CourbetPalette.red700,
      text: CourbetPalette.red700,
    },

    callToAction: {
      background: CourbetPalette.teal100,
      border: CourbetPalette.teal700,
      color: CourbetPalette.teal800,
      text: CourbetPalette.teal700,
    },
  },
};

export default CourbetDarkColorTheme;
