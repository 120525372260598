import CourbetPalette from '../color-palettes/CourbetPalette';

import { IColorTheme } from './IColorTheme';

const PRIMARY_EMPHASIS_COLOR = '#8661CD';
const SECONDARY_EMPHASIS_COLOR = '#C09FFF';

const CourbetLightColorTheme: IColorTheme = {
  // Text
  color: '#2F2F48',
  secondaryColor: '#A2A2AE',
  tertiaryColor: '#747485',
  emphasisColor: PRIMARY_EMPHASIS_COLOR,
  secondaryEmphasisColor: SECONDARY_EMPHASIS_COLOR,
  textSelectionColor: '#F5F5F9',

  // Backgrounds
  background100: '#D7D7D7',
  background200: '#DCDCDC',
  background300: '#E1E1E1',
  background400: '#E8E8E8',
  background500: '#F0F0F0',
  background600: '#F3F3F3',
  background800: '#F8F8F8',
  background900: '#FCFCFC',
  background1000: '#FFFFFF',
  textSelectionBackground: '#75649C',

  // Borders
  border: '#E8E8EB',
  secondaryBorder: '#DCDCDC',
  tertiaryBorder: '#B1B1B1',

  // Brand variants
  lightMint: '#67BF7F',
  lightViolet: '#5552CC',
  lightPurple: '#C09FFF',
  lightTeal: '#3FA6A6',

  // Avatar colors
  avatarColors: [
    '#72D9D9',
    '#3B9DE4',
    '#5D75F2',
    '#8885FF',
    '#9AF2B2',
    '#62E285',
    '#92E262',
    '#C4F980',
    '#F9D780',
    '#FBD062',
    '#FBB64E',
    '#DE6E4A',
    '#FC9D75',
    '#FBA2CD',
    '#EE67A7',
    '#D147AA',
  ],

  // Variants
  variant: {
    primary: {
      background: CourbetPalette.purple100,
      border: SECONDARY_EMPHASIS_COLOR,
      color: SECONDARY_EMPHASIS_COLOR,
      text: PRIMARY_EMPHASIS_COLOR,
    },

    secondary: {
      background: CourbetPalette.gray10,
      border: CourbetPalette.gray700,
      color: CourbetPalette.gray700,
      text: CourbetPalette.gray700,
    },

    info: {
      background: CourbetPalette.purple100,
      border: CourbetPalette.purple600,
      color: CourbetPalette.purple700,
      text: CourbetPalette.purple600,
    },

    success: {
      background: CourbetPalette.mint200,
      border: CourbetPalette.mint700,
      color: CourbetPalette.mint800,
      text: CourbetPalette.mint700,
    },

    warning: {
      background: CourbetPalette.orange100,
      border: CourbetPalette.orange700,
      color: CourbetPalette.orange700,
      text: CourbetPalette.orange700,
    },

    danger: {
      background: CourbetPalette.red100,
      border: CourbetPalette.red700,
      color: CourbetPalette.red700,
      text: CourbetPalette.red700,
    },

    callToAction: {
      background: CourbetPalette.teal100,
      border: CourbetPalette.teal700,
      color: CourbetPalette.teal800,
      text: CourbetPalette.teal700,
    },
  },
};

export default CourbetLightColorTheme;
